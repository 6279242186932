import { type IconProps } from '../../types';
import { IconWrapper } from './style';

export const Close = ({ color, rotation, width = '24px', height = '24px', cursor }: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ? rotation : 0})`}
    >
      <path
        d="M18.5 5.5L5.5 18.5"
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-1, -1)"
      />
      <path
        d="M5.5 5.5L18.5 18.5"
        stroke={color ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-1, -1)"
      />
    </svg>
  </IconWrapper>
);
