import { type ReactNode, type MouseEvent, forwardRef, type LegacyRef } from 'react';
import { colors } from '../../../themes';
import { Spinner } from '../../Feedback/Spinner';
import { StyledIconButton } from './style';

export type IconButtonProps = {
  children?: ReactNode;
  id?: string;
  variant?: 'primary' | 'secondary';
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: ((e: MouseEvent<HTMLButtonElement>) => void) | (() => void);
};

const IconButton = forwardRef(
  (
    { children, id, variant, isLoading, disabled, onClick, ...rest }: IconButtonProps,
    ref: LegacyRef<HTMLButtonElement>,
  ) => {
    return (
      <StyledIconButton
        data-testid="icon-button"
        id={id}
        variant={variant}
        isLoading={isLoading}
        disabled={disabled || isLoading}
        onClick={(e) => {
          e.stopPropagation();
          if (onClick) {
            if (e) {
              onClick(e);
            }
          }
        }}
        ref={ref}
        {...rest}
      >
        {isLoading ? <Spinner size="tiny" color={colors.primary.blue} /> : children}
      </StyledIconButton>
    );
  },
);

IconButton.displayName = 'IconButton';

export { IconButton };
