// prettier-ignore
const environmentVariables = {
  ...import.meta.env,
  PORTAL_DISABLE_I18NEXT_LOGGER: import.meta.env.PORTAL_DISABLE_I18NEXT_LOGGER,
  PORTAL_DISABLE_MIRAGE_LOGGER: import.meta.env.PORTAL_DISABLE_MIRAGE_LOGGER,
  PORTAL_DISABLE_REACT_STRICT_MODE: import.meta.env.PORTAL_DISABLE_REACT_STRICT_MODE,
  PORTAL_DISABLE_REDUX_LOGGER: import.meta.env.PORTAL_DISABLE_REDUX_LOGGER,
  PORTAL_MOCK_API: import.meta.env.PORTAL_MOCK_API,
  PORTAL_RECYCLING_API_URL: import.meta.env.PORTAL_RECYCLING_API_URL,
  PORTAL_RECYCLING_USER_ADMIN_API_URL: import.meta.env.PORTAL_RECYCLING_USER_ADMIN_API_URL,
  PORTAL_RECYCLING_USER_ADMIN_BASE_PATH: import.meta.env.PORTAL_RECYCLING_USER_ADMIN_BASE_PATH,
  PORTAL_AWS_S3_ASSETS_URL: import.meta.env.PORTAL_AWS_S3_ASSETS_URL,
  PORTAL_RECYCLING_API_BASE_PATH: import.meta.env.PORTAL_RECYCLING_API_BASE_PATH,  
  PORTAL_VERSION: import.meta.env.PORTAL_VERSION,
  PORTAL_ENV: import.meta.env.PORTAL_ENV,
  PORTAL_UNLEASH_PROXY_CLIENT_TOKEN: import.meta.env.PORTAL_UNLEASH_PROXY_CLIENT_TOKEN,
  PORTAL_UNLEASH_PROXY_URL: import.meta.env.PORTAL_UNLEASH_PROXY_URL,
  PORTAL_DISABLE_OPTIMIZELY: import.meta.env.PORTAL_DISABLE_OPTIMIZELY,
  PORTAL_GOOGLE_MAPS_API_KEY: import.meta.env.PORTAL_GOOGLE_MAPS_API_KEY,
  PORTAL_ONE_SPA: import.meta.env.PORTAL_ONE_SPA,
  ...import.meta.env.DEV && process.env
} as const;

export const env = (name: keyof typeof environmentVariables, defaultValue?: string) => {
  const value = environmentVariables[name];

  if (typeof value === 'string') {
    return value;
  }

  if (typeof defaultValue === 'string') {
    return defaultValue;
  }

  throw Error(`Environment variable '${String(name)}' is not set.`);
};
