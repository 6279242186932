import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../themes/stena-recycling';
import { type StyledIconButtonProps } from '../../../types';
import { animationTransition } from '../../helpers/styleHelpers';

export const StyledIconButton = styled.button<StyledIconButtonProps>`
  display: flex;
  position: relative;
  border: none;
  margin: 0;
  user-select: none;
  height: ${spacing.mediumLow};
  width: ${spacing.mediumLow};
  min-width: ${spacing.mediumLow};
  padding: 6px;
  border-radius: 50%;
  transition: background-color ${animationTransition};

  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${typography.other.button.label};

  ${({ variant }) => getButtonVariant(variant)}
`;

export const Label = styled.span<{ loading?: boolean }>`
  opacity: ${({ loading }) => (loading ? 0 : 1)};
`;

const getButtonVariant = (variant?: 'primary' | 'secondary') => {
  switch (variant) {
    case 'secondary':
      return css`
        background-color: transparent;
        color: ${colors.base.black};

        &:disabled {
          color: ${colors.monochrome.grey40};
          pointer-events: none;
        }

        &:hover:not(:disabled) {
          background-color: ${colors.monochrome.grey20};
          // Test using rgba instead of hex
          // background-color: rgba(0, 0, 0, 0.11);
        }

        &:active:not(:disabled) {
          background-color: ${colors.monochrome.grey30};
          // Test using rgba instead of hex
          // background-color: rgba(0, 0, 0, 0.18);
        }
      `;
    case 'primary':
    default:
      return css`
        background-color: ${colors.primary.blue};
        color: ${colors.base.white};

        &:disabled {
          background-color: ${colors.monochrome.grey20};
          color: ${colors.monochrome.grey40};
          pointer-events: none;
        }

        &:hover:not(:disabled) {
          background-color: ${colors.primary.blueShade1};
        }

        &:active:not(:disabled) {
          background-color: ${colors.primary.blueShade2};
        }
      `;
  }
};
