import { type User, type IdToken, useAuth0 } from '@auth0/auth0-react';

import { createContext, type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { type StenaPermissions } from '@stenarecycling/customer-portal-types';
import { useTransactionId } from '../hooks/useTransactionId';
import { getApiUrl } from '../utils/environment';
import type { BasicProfile, Profile } from './Profile';
export const ProfileContext = createContext<ProfileValue | null>(null);

export type ProfileValue = {
  profile: Profile | null;
  loading: boolean;
  isAuthenticated: boolean;
  updateProfile: () => Promise<void>;
  error?: boolean;
  basicProfile: BasicProfile | null; //Needed to optimize app loading times
};

export type ProfileProviderProps = {
  children: ReactNode;
};
export const ProfileProvider = ({ children }: ProfileProviderProps) => {
  const { isAuthenticated, user, getIdTokenClaims, getAccessTokenSilently, isLoading } = useAuth0();
  const [loading, setLoading] = useState(isLoading);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [basicProfile, setBasicProfile] = useState<BasicProfile | null>(null);

  const [error, setError] = useState(false);

  const transactionId = useTransactionId();

  const fetchPermissions = useCallback(
    async (userId: string): Promise<StenaPermissions | null> => {
      const url = `${getApiUrl()}/usermanagement/user/${encodeURIComponent(userId)}/permissions`;
      const accessToken = await getAccessTokenSilently();
      const headers = new Headers();

      headers.set('Authorization', `Bearer ${accessToken}`);
      headers.set('X-Transaction-Id', transactionId);

      return fetch(url, { headers })
        .then((d) => {
          if (!d.ok) {
            throw new Error("Failed to call ' + url");
          }

          return d.json() as Promise<StenaPermissions>;
        })
        .catch(() => {
          setError(true);

          return null;
        });
    },
    [getAccessTokenSilently, transactionId],
  );

  const parseProfileFromToken = useCallback(async () => {
    if (user) {
      const claims = await getIdTokenClaims();
      const profileFromClaims = mapToProfile(user, claims);

      setBasicProfile({
        userId: profileFromClaims.userId ?? '',
        email: profileFromClaims.email ?? '',
        language: profileFromClaims.language ?? 'en',
      });

      if (profileFromClaims.permissions?.tooManyPartners) {
        const permissions = await fetchPermissions(user.sub ?? '');

        profileFromClaims.permissions = permissions;
      }

      setProfile({ ...profileFromClaims, language: profileFromClaims.language ?? 'en' });
      setLoading(false);
    }
  }, [user, getIdTokenClaims, fetchPermissions]);

  useEffect(() => {
    if (isAuthenticated && !profile) {
      setLoading(true);
      void parseProfileFromToken();
    }
  }, [isAuthenticated, parseProfileFromToken, profile]);

  const updateProfile = useCallback(async () => {
    await getAccessTokenSilently({ cacheMode: 'off' });
    await parseProfileFromToken();
  }, [getAccessTokenSilently, parseProfileFromToken]);

  const profileValue = useMemo(
    () => ({
      loading,
      profile,
      isAuthenticated,
      error,
      updateProfile,
      basicProfile,
    }),
    [loading, profile, isAuthenticated, error, updateProfile, basicProfile],
  );

  return <ProfileContext.Provider value={profileValue}>{children}</ProfileContext.Provider>;
};

const mapToProfile = (user: User, idTokenClaims?: IdToken): Profile => {
  const profile = {} as Profile;

  profile.email = idTokenClaims?.email ?? user.email;
  profile.firstName = idTokenClaims?.given_name ?? user.given_name;
  profile.lastName = idTokenClaims?.family_name ?? user.family_name;
  profile.lastLoggedIn = user.last_login;

  if (!idTokenClaims) {
    return profile;
  }

  profile.userId = idTokenClaims.sub;
  const namespace = 'https://stenarecycling.com/';

  profile.fullName = idTokenClaims[namespace + 'fullName'] ?? user.nickname;
  profile.language = idTokenClaims[namespace + 'language'];
  profile.phone = idTokenClaims[namespace + 'phone'];
  profile.permissions = idTokenClaims[namespace + 'permissions'];

  return profile;
};
