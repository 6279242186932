import { type ReactNode } from 'react';
import { type Spacing } from '../../../themes/stena-recycling/spacing';
import { StyledParagraph } from './styles';

export type ParagraphProps = {
  variant: 'small' | 'medium' | 'large';
  bold?: boolean;
  mt?: Spacing;
  mb?: Spacing;
  color?: string;
  children?: ReactNode;
};

export const Paragraph = (props: ParagraphProps) => {
  return <StyledParagraph {...props} />;
};
